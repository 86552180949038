import React from 'react';

import { Form, Input, Button, Alert } from 'antd';
import { DatePicker } from 'antd';
import { MinusCircleOutlined, PlusOutlined, CopyOutlined, DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';

const yaml = require('js-yaml');

const { Search } = Input;

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
    },
};
const formItemLayoutWithOutLabel = {
    wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 20, offset: 4 },
    },
};

class MainForm extends React.Component {

    formRef = React.createRef();
    searchRef = React.createRef();
    
    constructor(props) {
        super(props);

        this.state = {
            yaml: undefined,
            formValues: {},
            loadingAPI: false,
            error: false
        }

        this.onFinish = this.onFinish.bind(this);
        this.onFinishFailed = this.onFinishFailed.bind(this);
        this.onValuesChange = this.onValuesChange.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.generateYAML = this.generateYAML.bind(this)
        this.clearData = this.clearData.bind(this)

        this.selectData = this.selectData.bind(this)
        this.downloadData = this.downloadData.bind(this)
        this.preRef = React.createRef();
    }

    selectData () {
        let range = new Range();
        range.selectNode(this.preRef.current)
        document.getSelection().addRange(range)
    }

    downloadData (e) {
        const blob = new Blob([this.preRef.current.innerText]);
        const fileDownloadUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = fileDownloadUrl;
        link.setAttribute(
        'download',
        `CITATION.cff`,
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    }

    onSearch (data) {
        data = data.toLowerCase()

        var arxivID = /arxiv:(.+)/g.exec(data);
        if (!arxivID) arxivID = /arxiv.org\/abs\/(.+)/g.exec(data);
        
        var DOI = /10.\d{4,9}\/[-._;()/:A-Z0-9]+$/i.exec(data);

        if (DOI) {
            data = DOI[0]
            this.fetchAPI(data, '/api/getDataPaper?doi=')
        } else if (arxivID) {
            data = arxivID[1]
            console.log(data)
            this.fetchAPI(data, '/api/getDataPaper?arxiv=')
        } else {
            this.setState({
                loadingAPI: false,
                error: 'Provide a valid DOI or Arxiv ID'
            })
        }
    } 

    fetchAPI (data, url) {
        this.setState({
            loadingAPI: true,
            error: false
        })

        fetch(url + data)
            .then( (response) => {
                return response.json()
            })
            .then( (myJson) => {
                if ('error' in myJson) {
                    this.setState({
                        error: myJson['error']
                    })
                    this.clearData(false)
                } else {
                    if ('date-released' in myJson) {
                        // myJson['date-released'] = Date.parse(myJson['date-released'])
                        myJson['date-released'] = moment(myJson['date-released'])
                    }
    
                    myJson['version'] = "1.0.0"
    
                    this.setState({
                        formValues: myJson,
                        loadingAPI: false,
                        error: false
                    })
                    this.formRef.current.resetFields()
                    this.generateYAML(myJson)
                    this.formRef.current.validateFields()
                }
            })
    }

    clearData (clearError=true) {
        this.setState({
            formValues: {},
            loadingAPI: false,
            yaml: null,
            error: (clearError ? false : this.state.error)
        }, () => {
            this.formRef.current.resetFields()
            // this.searchRef.current.value("")
        })
    }

    onFinish (values) {
        console.log('Success:', values);
      };
    
    onFinishFailed (errorInfo) {
    console.log('Failed:', errorInfo);
    };

    onValuesChange (changedValues, allValues) {
        this.formRef.current.validateFields()
        this.generateYAML(allValues)
    };

    generateYAML (allValues) {
        var obj = allValues
        Object.keys(obj).forEach(key => (obj[key] === undefined || obj[key] === null || obj[key] === "") && delete obj[key])

        if ('date-released' in obj && obj['date-released']) {
            // obj['date-released'] = obj['date-released'].format('YYYY-MM-DD')
            obj['date-released'] = obj['date-released'].toDate()
            obj['date-released'].setHours(0, 0, 0, 0)
        }

        if(Object.keys(obj).length){
            obj['cff-version'] = "1.2.0"
            this.setState({
                // yaml: yaml.load(obj).dump()
                yaml: yaml.dump(obj, {
                    lineWidth: -1,
                    flowLevel: 3,
                    styles: {
                        "!!timestamp": 'iso8601'
                    }
                })
            })
        } else {
            this.setState({
                yaml: null
            })
        }
    }

    render() {
        return (
        <div>
        {this.state.error && <Alert
            message="Error"
            description={this.state.error}
            type="error"
            showIcon
            style={{padding: '1rem', margin: '1rem'}}
        />}
        <div className="col-container">
        <Search 
                placeholder="Insert DOI (including url) or ArXiv ID (including url) to autofill values" 
                enterButton 
                onSearch={this.onSearch}
                loading={this.state.loadingAPI}
                ref={this.searchRef}
                allowClear={true}/>
        <Button danger type="primary" style={{marginLeft: '2rem'}} onClick={this.clearData}>Clear</Button>
        </div>

        <div className="col-container">
        <div className="col">
        <Form
            name="citationData"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            initialValues={this.state.formValues}
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
            onValuesChange={this.onValuesChange}
            style={{background: 'rgb(255,255,255)', padding: '1rem'}}
            ref={this.formRef}
        >
            <Form.Item
            label="Title"
            name="title"
            rules={[{ required: true, message: 'Please input title' }]}
            >
            <Input />
            </Form.Item>

            <Form.Item
            label="Abstract"
            name="abstract"
            // rules={[{ required: true, message: 'Please input your username!' }]}
            >
            <Input />
            </Form.Item>

            <Form.Item
            label="DOI"
            name="doi"
            // rules={[{ required: true, message: 'Please input your username!' }]}
            >
            <Input />
            </Form.Item>

            <Form.Item
            label="Repository code"
            name="repository-code"
            rules={[{ required: true, message: 'Please input repository url' }]}
            >
            <Input />
            </Form.Item>

            <Form.Item
            label="Version"
            name="version"
            // rules={[{ required: true, message: 'Please input version of the source code' }]}
            >
            <Input />
            </Form.Item>

            <Form.Item
            label="Date released"
            name="date-released"
            rules={[{ required: true, message: 'Please input released date' }]}
            // getValueFromEvent={ (e) => console.dir(e)}
            >
            <DatePicker />
            </Form.Item>


            <Form.Item
            label="Message"
            name="message"
            // rules={[{ required: true, message: 'Please input your username!' }]}
            >
            <Input />
            </Form.Item>

            <Form.List
                name="authors"
            >
                {(fields, { add, remove }, { errors }) => (
                <>
                    {fields.map((field, index) => (
                    // <Space key={field.key} align="baseline">
                    <Form.Item
                        {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                        label={index === 0 ? 'Author' : ''}
                        required={false}
                        key={field.key}
                    >
                        <Form.Item
                        {...field}
                        name={[field.name, 'given-names']}
                        fieldKey={[field.fieldKey, 'given-names']}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                            {
                            required: true,
                            whitespace: true,
                            message: "Please input author's given name",
                            },
                        ]}
                        noStyle
                        >
                        <Input placeholder="given name" style={{ width: '60%' }} />
                        </Form.Item>

                        <Form.Item
                        {...field}
                        validateTrigger={['onChange', 'onBlur']}
                        name={[field.name, 'family-names']}
                        fieldKey={[field.fieldKey, 'family-names']}
                        rules={[
                            {
                            required: true,
                            whitespace: true,
                            message: "Please input author's family name",
                            },
                        ]}
                        noStyle
                        >
                        <Input placeholder="family name" style={{ width: '60%' }} />
                        </Form.Item>

                        <Form.Item
                        {...field}
                        validateTrigger={['onChange', 'onBlur']}
                        name={[field.name, 'affiliation']}
                        fieldKey={[field.fieldKey, 'affiliation']}
                        // rules={[
                        //     {
                        //     required: true,
                        //     whitespace: true,
                        //     message: "Please input author's affiliation",
                        //     },
                        // ]}
                        noStyle
                        >
                        <Input placeholder="affiliation" style={{ width: '60%' }} />
                        </Form.Item>

                        {fields.length > 0 ? (
                        <MinusCircleOutlined
                            className="dynamic-delete-button"
                            onClick={() => remove(field.name)}
                            style={{paddingLeft: '5px'}}
                        />
                        ) : null}
                    </Form.Item>
                    // </Space> 
                    ))}
                    <Form.Item>
                    <Button
                        type="dashed"
                        onClick={() => add()}
                        style={{ width: '60%' }}
                        icon={<PlusOutlined />}
                    >
                        Add author
                    </Button>
                    <Form.ErrorList errors={errors} />
                    </Form.Item>
                </>
                )}
            </Form.List>

            <Form.List
                name="keywords"
            >
                {(fields, { add, remove }, { errors }) => (
                <>
                    {fields.map((field, index) => (
                    // <Space key={field.key} align="baseline">
                    <Form.Item
                        {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                        label={index === 0 ? 'Keyword' : ''}
                        required={false}
                        key={field.key}
                    >
                        <Form.Item
                        {...field}
                        // name={[field.name, 'keywordX']}
                        fieldKey={[field.fieldKey, 'keyword']}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                            {
                            required: true,
                            whitespace: true,
                            message: "Please input a keyword",
                            },
                        ]}
                        noStyle
                        >
                        <Input placeholder="keyword" style={{ width: '60%' }} />
                        </Form.Item>

                        {fields.length > 0 ? (
                        <MinusCircleOutlined
                            className="dynamic-delete-button"
                            onClick={() => remove(field.name)}
                            style={{paddingLeft: '5px'}}
                        />
                        ) : null}
                    </Form.Item>
                    // </Space>
                    ))}
                    <Form.Item>
                    <Button
                        type="dashed"
                        onClick={() => add()}
                        style={{ width: '60%' }}
                        icon={<PlusOutlined />}
                    >
                        Add keyword
                    </Button>
                    <Form.ErrorList errors={errors} />
                    </Form.Item>
                </>
                )}
            </Form.List>
        </Form>
        </div>

        <div className="col format" style={{width: '40vw'}}>
            { this.state.yaml && <div style={{float: 'right'}}> 
                <Button type="secondary" style={{marginLeft: '0rem'}} onClick={this.selectData}> <CopyOutlined /> Copy</Button> 
                <Button type="secondary" style={{marginLeft: '1rem'}} onClick={this.downloadData}> <DownloadOutlined /> Download</Button> 
            </div>}
            <pre ref={this.preRef}>
                {
                    // this.state.yaml && yaml.load(this.state.yaml).dump()
                    this.state.yaml
                }
            </pre>
        </div>
        </div>
        </div>
        )
    }  

}

export default MainForm;
