import React from 'react';
import './App.css';

import { Layout, Breadcrumb } from 'antd';

import MainForm from './MainForm';

import 'antd/dist/antd.css';

const { Header, Content, Footer } = Layout;

function App() {
  // const [date, setDate] = useState(null);
  // useEffect(() => {
  //   async function getDate() {
  //     const res = await fetch('/api/date');
  //     const newDate = await res.text();
  //     setDate(newDate);
  //   }
  //   getDate();
  // }, []);

  return (
    <Layout className="layout">
      <Header>
        <span className="title"> <strong>Automatic generator / online editor </strong> of Citation File Format (CFF) files for publications </span>
        {/* <div className="github">
          <span> Visit the source code on Github </span>
        </div> */}
      </Header>
      <Content>
        <Breadcrumb style={{ margin: '16px 0' }}>
          {/* <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>List</Breadcrumb.Item>
          <Breadcrumb.Item>App</Breadcrumb.Item> */}
        </Breadcrumb>
        <div className="site-layout-content"  style={{marginBottom: '0px', paddingBottom: '0px'}}>
          <MainForm />
        </div>
      </Content>
      <Footer style={{ textAlign: 'center', paddingTop: '5px' }}>
        <a href="https://mcrespo.me">Miguel Crespo</a> ©2021 - Powered by <a href='https://vercel.com/'>Vercel</a>
      </Footer>
    </Layout>
  );
}

export default App;
